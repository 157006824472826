// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as LocalityShowBannerMap from "./components/LocalityShowBannerMap.res.js";

function LocalityShowMap(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(LocalityShowBannerMap.make, {
                    locations: props.locations
                  }),
              className: props.className
            });
}

var make = LocalityShowMap;

var $$default = LocalityShowMap;

export {
  make ,
  $$default as default,
}
/* react/jsx-runtime Not a pure module */
