// extracted by mini-css-extract-plugin
export var column = "LocalityShowBannerMap__column__mlJKe";
export var flex = "LocalityShowBannerMap__flex__IHdAy";
export var flexColumn = "LocalityShowBannerMap__flexColumn__ot1zG";
export var gap1 = "LocalityShowBannerMap__gap1__AH04p";
export var gap2 = "LocalityShowBannerMap__gap2__K9w68";
export var gap3 = "LocalityShowBannerMap__gap3__ziVpE";
export var gap4 = "LocalityShowBannerMap__gap4__wVufQ";
export var gap5 = "LocalityShowBannerMap__gap5__zsxmE";
export var heading = "LocalityShowBannerMap__heading__WPYq2";
export var mapElement = "LocalityShowBannerMap__mapElement__SOM2T";
export var row = "LocalityShowBannerMap__row__gfpeE";
export var wrapper = "LocalityShowBannerMap__wrapper__aMCZo";